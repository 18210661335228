<template>
  <div class="trial">
    <div class="context">
      <div class="form">
        <div class="info">
          <h2>申请免费试用</h2>
          <p>请完善信息，我们会尽快联系您，并为您提供免费授权。</p>
          <div v-if="!islogin">
            <div class="row">
              <span class="imp">*</span>
              <input type="text" v-model="name" placeholder="请填写您的姓名" @focus="onFocus" @blur="onBlur($event, 0)" />
              <div class="tips" :class="tip(0) ? 'tip' : ''">
                请输入您的姓名
              </div>
            </div>
            <div class="row">
              <span class="imp">*</span>
              <input type="text" v-model="telephone" placeholder="请填写您的手机号" @focus="onFocus" @blur="onBlur($event, 1)" />
              <div class="tips" :class="tip(1) ? 'tip' : ''">
                请输入正确的手机号
              </div>
            </div>
            <div class="enterprise">
              <span class="imp">*</span>
              <input type="text" v-model="number" placeholder="请输入短信验证码" @focus="onFocus" @blur="onBlur($event, 2)" />
              <button type="button" class="btn" v-show="show" @click="getmessage">
                获取验证码
              </button>
              <button type="button" class="btn" v-show="!show" style="background-color: #999;">
                {{ count }}
              </button>
              <div class="tips" :class="tip(2) ? 'tip' : ''">
                验证码输入错误
              </div>
            </div>
            <div class="row">
              <span class="imp">*</span>
              <input type="text" v-model="enterprise" placeholder="请填写完整企业名称" @focus="onFocus" @blur="onBlur($event, 3)" />
              <div class="tips" :class="tip(3) ? 'tip' : ''">
                请输入完整企业名称
              </div>
            </div>
            <button class="submit" :class="issure ? 'sure' : ''" @click="submit">
              立即提交
            </button>
            <div class="service"><input type="checkbox" v-model="istrue" />我已阅读并同意<span @click="agree">《服务协议》</span></div>
          </div>
          <div class="userSuccess" v-else>
            <!-- <div>我们会尽快联系您</div> -->
            <el-button type="success" class="experience" @click="experience">点击立即体验</el-button>
          </div>
        </div>
      </div>
    </div>
    <FootMain></FootMain>
  </div>
</template>

<script>
import FootMain from '@/components/m/foot_main/index.vue'
export default {
  data() {
    return {
      name: '',
      telephone: '',
      number: '',
      enterprise: '',
      istrue: false,
      isShow: [],
      timer: null,
      count: 0,
      show: true
    }
  },
  methods: {
    async getmessage() {
      let phone = document.getElementsByClassName('input_phone')[0]
      let phoneCodeVerification = /^[1][3,4,5,7,8][0-9]{9}$/
      let bool = phoneCodeVerification.test(this.telephone)
      if (!bool) {
        // this.$message({
        //   type: "warning",
        //   message: "手机号不正确/手机号为空",
        // });
        phone.style.borderColor = 'red'
        this.isShow.push(1)
      } else {
        let res = await this.$http.request({
          url: '/api/getSms',
          method: 'POST',
          data: {
            phone: this.telephone,
            t:new Date().getTime()
          }
        })
        if (res.returnCode == 1) {
          this.getCode()
          this.$message({
            type: 'success',
            message: '验证码发送成功注意查收'
          })
        }
      }
    },
    onFocus(e) {
      e.currentTarget.style.border = '1px solid #4aafe9'
    },
    onBlur(e, number) {
      let phoneCodeVerification = /^[1][3,4,5,7,8][0-9]{9}$/
      let value = e.currentTarget.value
      let isNum = false
      if (number == 2 && value) {
        let arr = value.split('')
        isNum = arr.every(val => {
          return parseInt(val) >= 0
        })
        if (arr.length != 4) {
          isNum = false
        }
      }
      // console.log(isNum);
      let bool = phoneCodeVerification.test(value)
      if (!value || (number == 1 && !bool) || (number == 2 && !isNum)) {
        e.currentTarget.style.borderColor = '#ff5d5d'
        if (this.isShow.indexOf(number) < 0) {
          this.isShow.push(number)
        }
      } else {
        let index = this.isShow.indexOf(number)
        if (index >= 0) {
          this.isShow.splice(index, 1)
          e.currentTarget.style.borderColor = '#aaa'
        }
        // console.log(e.currentTarget.value,text);
      }
    },
    agree() {
      let href = 'https://res.ushareyun.net/usharen_protocol.html?t=' + new Date().getTime()
      window.open(href, '_blank')
    },
    getCode() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    submit() {
      let userInfo = {
        userName: this.name,
        phone: this.telephone,
        code: this.number,
        company: this.enterprise
      }
      if (this.issure && this.checkForm()) {
        this.$store.dispatch('submitUserInfo', userInfo)
      }
    },
    checkForm() {
      let errMsg = ''
      if (this.name == '') {
        errMsg = '请输入您的姓名'
      } else if (this.telephone == '') {
        errMsg = '请输入正确的手机号'
      } else if (this.number == '') {
        errMsg = '验证码输入错误'
      } else if (this.enterprise == '') {
        errMsg = '请输入完整企业名称'
      }
      if (errMsg) {
        this.$message({
          type: 'warning',
          message: errMsg
        })
        return false
      }
      return true
    },
    experience() {
      this.$router.push('/m/download')
    }
  },
  computed: {
    tip() {
      return function(val) {
        if (this.isShow.indexOf(val) >= 0) return true
        return false
      }
    },
    issure() {
      if (this.isShow.length == 0 && this.istrue && this.name != '' && this.telephone != '' && this.number != '' && this.enterprise != '') return true
      return false
    },
    islogin() {
      return this.$store.state.islogin
    }
  },
  components: {
    FootMain
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/m/trial/index.scss';
</style>
<style type="text/css">
.userSuccess {
  padding-top: 2rem;
  font-size: 2rem;
}
span.imp{
  color: #f00;
  margin-right: 0.5rem;
}
.experience {
  color: white;
}
</style>
